/* Responsive design: applies padding to the container when screen width is less than 991px */
@media only screen and (max-width: 991px) {
    .s_c {
        padding-top: 40px; /* Adds padding at the top of the container */
    }
}

/* Initial state for page transitions: page starts offscreen from the bottom */
.page-enter {
    transform: translateY(100%); /* Page is translated 100% down the Y-axis */
}

/* Active state for page transitions: page slides into view */
.page-enter-active {
    transform: translateY(0%); /* Page is in its final position (fully in view) */
    transition: all 0ms ease-out; /* No delay for the transition */
}

/* Initial state for exiting page: page is in its normal position */
.page-exit {
    transform: translateY(0%); /* Page is in its normal position */
    position: absolute; /* Fixes the element's position for smooth transitions */
    left: 0; /* Aligns to the left */
    right: 0; /* Aligns to the right */
    top: 0; /* Aligns to the top */
}

/* Active state for page transitions when exiting: page slides out upwards */
.page-exit-active {
    position: absolute; /* Keeps the page fixed while transitioning */
    left: 0; /* Aligns to the left */
    right: 0; /* Aligns to the right */
    top: 0; /* Aligns to the top */
    transform: translateY(-130%); /* Moves the page 130% up the Y-axis */
    transition: all 0ms ease-out; /* No delay for the transition */
}

/* Applies maximum width limits to various container classes when screen width is greater than 1400px */
@media (min-width: 1400px) {
    .container,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl {
        max-width: 1140px; /* Sets the maximum width for containers */
    }
}
