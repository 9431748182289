/* Header is fixed at the top of the page */
.site__header {
    position: fixed; /* Keeps the header fixed while scrolling */
    top: 0; /* Positions the header at the top */
    padding-left: 10px; /* Adds padding on the left */
    padding-right: 10px; /* Adds padding on the right */
}

/* Styling for the menu button */
.menu__button {
    color: var(--text-color); /* Uses a CSS variable for the text color */
}

/* Menu button hover and focus states */
.menu__button:focus,
.menu__button:hover {
    color: var(--text-color); /* Maintains the same text color on hover/focus */
    box-shadow: unset; /* Removes any default box shadow */
}

/* Icon sizing inside the menu button */
.menu__button svg {
    width: 2em; /* Sets the icon width */
    height: 2em; /* Sets the icon height */
    fill: var(--text-color-2); /* Fills the icon with a secondary color */
    color: var(--text-color-2); /* Ensures the text color is consistent */
}

/* Styling for navigation links */
.nav_ac {
    padding: 15px 15px; /* Adds padding around the link */
    margin: 0; /* Removes default margin */
    border: unset; /* Removes any border */
    background: transparent; /* Transparent background */
    font-size: 1.25rem; /* Sets the font size */
    font-family: Marcellus; /* Uses a custom font */
    color: var(--text-color-2); /* Sets the text color */
    line-height: 2; /* Sets line height for better spacing */
    height: 50px; /* Sets a fixed height */
    font-weight: bold; /* Makes the text bold */
    z-index: 1000; /* Ensures links are on top of other elements */
}

/* Link hover effect */
.nav_ac:hover {
    color: var(--text-color-2); /* Keeps the text color consistent on hover */
}

/* Border elements around the header */
.br-top,
.br-bottom,
.br-right,
.br-left {
    position: fixed; /* Keeps the borders fixed on the screen */
    z-index: 999999; /* Ensures borders are on top */
    background: transparent; /* Transparent background for borders */
}

/* Top border positioning */
.br-top {
    top: 0; /* Positions the top border at the top of the page */
    height: 10px; /* Sets the height of the top border */
    left: 0; /* Aligns the top border to the left */
    width: 100%; /* Makes the border span the full width */
}

/* Bottom border positioning */
.br-bottom {
    bottom: 0; /* Positions the bottom border at the bottom */
    left: 0; /* Aligns the bottom border to the left */
    height: 0; /* No height for the bottom border */
    width: 100%; /* Makes the border span the full width */
}

/* Right border positioning */
.br-right {
    width: 10px; /* Sets the width of the right border */
    right: 0; /* Aligns the right border to the right side */
    top: 0; /* Positions the right border at the top */
    height: 100%; /* Makes the border span the full height of the page */
}

/* Left border positioning */
.br-left {
    width: 10px; /* Sets the width of the left border */
    left: 0; /* Aligns the left border to the left side */
    top: 0; /* Positions the left border at the top */
    height: 100%; /* Makes the border span the full height of the page */
}

/* Wrapper for the menu content */
.cortina__wrapper-menu {
    position: relative; /* Relative positioning within the parent container */
    width: 100%; /* Full width */
    padding-top: 5em; /* Padding at the top of the menu */
    padding-bottom: 3em; /* Padding at the bottom */
    height: 100%; /* Full height */
    overflow-y: auto; /* Allows vertical scrolling if content overflows */
}

/* Main site navigation styles */
.site__navigation {
    height: 100%; /* Full height */
    left: 0; /* Aligns the navigation to the left */
    overflow: hidden; /* Hides any overflow content */
    position: fixed; /* Keeps the navigation fixed on the page */
    top: 0; /* Aligns the navigation to the top */
    width: 100%; /* Full width */
    visibility: hidden; /* Initially hidden */
}

/* Menu open state */
.menu__opend {
    visibility: visible !important; /* Makes the menu visible when opened */
}

/* Main menu and right menu styling with transitions */
.main__menu_ul,
.menu_right {
    opacity: 0; /* Initially invisible */
    position: relative; /* Relative positioning for layout */
    transition: 0.5s; /* Smooth transition */
    transition-delay: 0s; /* No delay initially */
    visibility: hidden; /* Initially hidden */
    z-index: 100; /* Ensures these elements are on top */
}

/* Right menu alignment */
.menu_right {
    text-align: center; /* Centers the content in the right menu */
}

/* Show menus when open */
.site__navigation.menu__opend .main__menu_ul,
.site__navigation.menu__opend .menu_right {
    opacity: 1; /* Makes the menus visible */
    transition-delay: 0.6s; /* Adds a delay for a smooth transition */
    visibility: visible; /* Ensures the menus are displayed */
}

/* List item styling for navigation */
.site__navigation .main__menu_ul li {
    list-style: none; /* Removes bullet points */
    margin: 10px 0; /* Adds vertical spacing between list items */
}

/* Anchor tag styling inside the navigation menu */
.site__navigation .main__menu_ul li a {
    color: var(--text-color); /* Sets the link color */
    display: block; /* Makes the links block elements */
    font-size: 2.5rem; /* Large font size for navigation links */
    text-decoration: none; /* Removes underline from links */
}

/* Background for the menu when it opens */
.bg__menu {
    position: absolute; /* Absolute positioning within the container */
    left: 0px; /* Aligns to the left */
    top: 0px; /* Aligns to the top */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    background-color: var(--primary-color); /* Background color from the primary color variable */
    will-change: transform; /* Optimizes the animation performance */
    transform: translateY(-100%); /* Initially slides the menu off-screen */
    transition: .5s ease all; /* Smooth transition for sliding */
}

/* When menu is open, slide it down */
.menu__opend .bg__menu {
    transform: translateY(0); /* Slides the menu into view */
}

/* Wrapper for the menu items */
.menu__wrapper {
    position: relative; /* Relative positioning */
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden auto; /* Hides horizontal overflow, allows vertical scroll */
}

/* Padding for the menu */
.the_menu {
    padding-top: 20vh; /* Adds vertical padding at the top */
    padding-bottom: 20vh; /* Adds vertical padding at the bottom */
    padding-left: 0; /* No padding on the left */
}

/* Adjust padding on larger screens */
@media (min-width: 992px) {
    .menu__container {
        margin-left: 33.3333%; /* Offset the menu by one-third of the screen width */
    }

    .the_menu {
        padding-top: 10vh; /* Reduces the top padding on larger screens */
        padding-bottom: 10vh; /* Reduces the bottom padding on larger screens */
    }
}

/* Menu item link styling */
.the_menu .menu_item > a {
    color: var(--text-color-2); /* Sets the link color */
    line-height: 1; /* Normal line height */
    font-size: 2rem; /* Font size for menu items */
    display: inline-block; /* Makes the links inline-block */
    position: relative; /* Allows positioning of hover effects */
    transition: color 250ms cubic-bezier(0, 0, 0.58, 1) 0s; /* Smooth transition for color change */
    padding: 4px 0px; /* Adds vertical padding */
    text-decoration: none; /* Removes underline from links */
    font-family: Marcellus; /* Uses the custom font */
}

/* Hover effect for menu items */
.the_menu .menu_item > a:hover {
    color: var(--text-color-3); /* Changes the color on hover */
}

/* Adjust font size for larger screens */
@media (min-width: 768px) {
    .the_menu .menu_item > a {
        font-size: 4.8vw; /* Sets the font size relative to screen width */
    }
}

/* Footer inside the menu */
.menu_footer {
    bottom: 0; /* Positions the footer at the bottom */
    font-family: Marcellus; /* Uses the custom font */
    font-size: 14px; /* Sets a small font size */
    background: var(--primary-color); /* Primary color for footer background */
}

/* Footer links styling */
.menu_footer a {
    color: var(--text-color-2); /* Sets the footer link color */
    margin-right: 10px; /* Adds spacing between footer links */
    text-decoration: none; /* Removes underline from footer links */
}
