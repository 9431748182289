/* Styles for the container of social icons */
.stick_follow_icon {
    top: 50%; /* Centers vertically */
    left: 30px; /* Positions the icon set from the left */
    width: 20px; /* Sets the width of the icon bar */
    height: 200px; /* Sets the height of the icon bar */
    position: fixed; /* Keeps the icons fixed while scrolling */
    margin-top: -100px; /* Centers the icon vertically relative to height */
}

/* Removes default list styling and ensures no padding or margin for the list of icons */
.stick_follow_icon ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

/* Styles for the social media icons */
.stick_follow_icon svg {
    width: 1.3em; /* Adjusts icon size */
    height: 1.3em; /* Adjusts icon size */
    fill: var(--text-color); /* Uses the text color defined in CSS variables */
}

/* Styles the "Find me" text below the social icons */
.stick_follow_icon p {
    top: 70px; /* Adjusts vertical position */
    left: -24px; /* Shifts the text to the left */
    width: 68px; /* Defines width of the text area */
    height: 20px; /* Defines height of the text area */
    color: var(--text-color); /* Uses the text color defined in CSS variables */
    font-size: 12px; /* Sets the font size */
    font-weight: 600; /* Makes the text bold */
    line-height: 1.2; /* Adjusts line height */
    white-space: nowrap; /* Prevents text from wrapping */
    position: relative; /* Positions the text relative to its normal flow */
    transform: rotate(-90deg); /* Rotates the text vertically */
}

/* Styles for each social media icon item */
.stick_follow_icon ul li {
    display: block; /* Each icon is displayed as a block for vertical stacking */
    font-size: 12px; /* Sets the font size (if needed) */
    text-align: center; /* Centers the icon within its container */
    margin-bottom: 10px; /* Adds spacing between each icon */
    transition: all .3s; /* Smooth transition for any changes */
}

/* Adds a small horizontal line under the "Find me" text */
.stick_follow_icon p:after {
    top: 9px; /* Adjusts vertical position of the line */
    right: -48px; /* Shifts the line to the right */
    width: 40px; /* Defines the length of the line */
    height: 1px; /* Sets the height (thickness) of the line */
    content: ""; /* Ensures the line is treated as a block */
    display: block; /* Ensures the line is displayed */
    position: absolute; /* Positions the line relative to the "Find me" text */
    background-color: var(--text-color); /* Sets the line color using the defined text color */
}

/* Responsive design adjustments for screen sizes smaller than 991px */
@media only screen and (max-width: 991px) {
    .stick_follow_icon {
        width: unset; /* Resets width */
        height: unset; /* Resets height */
        position: static; /* Changes position to static so it flows with the page */
        margin-top: unset; /* Removes vertical centering */
        display: flex; /* Displays the icon set in a row */
        flex-direction: row-reverse; /* Reverses the order of the icons */
        justify-content: center; /* Centers the icons horizontally */
        padding: 40px 0; /* Adds padding around the icon set */
        align-items: center; /* Aligns icons vertically in the center */
    }

    .stick_follow_icon p {
        top: unset; /* Removes the top positioning */
        left: unset; /* Removes the left positioning */
        width: unset; /* Resets width */
        height: unset; /* Resets height */
        white-space: nowrap; /* Keeps the text in a single line */
        position: relative; /* Relative positioning for flexibility */
        transform: unset; /* Resets rotation to horizontal */
        font-size: 17px; /* Increases font size for better readability on small screens */
        margin-right: 65px; /* Adds margin to the right of the "Find me" text */
    }

    .stick_follow_icon ul {
        margin-bottom: 20px; /* Adds margin at the bottom of the icon list */
    }

    .stick_follow_icon ul li {
        display: inline; /* Displays icons inline for horizontal stacking */
        margin-bottom: 29px; /* Adds space below each icon */
        margin-right: 10px; /* Adds space between each icon */
    }
}
