/* General section spacing */
.sec_sp {
    margin-bottom: calc(3rem + 5.128vw); /* Responsive bottom margin that increases with viewport width */
}

/* Styles for h3 elements within the sec_sp class */
.sec_sp h3 {
    /* Prevent text from wrapping to the next line */
    white-space: nowrap;

    /* Hide any content that overflows the element's box */

    /* Display an ellipsis (...) to represent clipped text */
    text-overflow: ellipsis;
}

/* Styles for the StoppingTypewriter container within sec_sp */
.sec_sp .typewriter-container {
    /* Make the container behave like an inline element, but allow setting width and height */
    display: inline-block;

    /* Ensure the container doesn't exceed the width of its parent element */
    max-width: 100%;
}

/* Section title style */
.section-title {
    font-size: 45px; /* Large font size for section titles */
}

/* Horizontal line style */
.t_border {
    border-color: var(--text-color) !important; /* Use the text color variable for border color */
}

/* Work timeline table styling */
.work-timeline-table {
    width: 100%; /* Ensure the table takes up the full width of its container */
    background-color: var(--bg-color); /* Use a custom background color defined elsewhere in the CSS */
    color: var(--text-color); /* Use a custom text color defined elsewhere in the CSS */
    border-collapse: collapse; /* Remove the default spacing between table cells */
    table-layout: fixed; /* Use a fixed table layout for more predictable column sizing */
}

/* Table header (th) styling */
.work-timeline-table th {
    text-align: left; /* Center the text content within header cells */
    padding: 10px; /* Add 10px of padding inside the header cells for spacing */
    border-bottom: 2px solid white; /* Add a 2px white border at the bottom of header cells */
    overflow: hidden; /* Hide any content that exceeds the cell's dimensions */
}

/* Styling for the immediate child div of header cells */
/* This targets the container of the CustomTypeWriter component in headers */
.work-timeline-table th > div {
    display: flex; /* Use flexbox for advanced layout control */
    justify-content: center; /* Center the content horizontally within the flex container */
    align-items: center; /* Center the content vertically within the flex container */
    height: 100%; /* Ensure the div takes up the full height of the th cell */
}

/* Table data (td) cell styling */
.work-timeline-table td {
    padding: 10px; /* Add 10px of padding inside the data cells for spacing */
    border: none; /* Remove any default borders from data cells */
    overflow: hidden; /* Hide any content that exceeds the cell's dimensions */
}

/* Set specific widths for each column */
/* First column (usually for "Position") */
.work-timeline-table th:nth-child(1),
.work-timeline-table td:nth-child(1) {
    width: 30%; /* Allocate 40% of the table's width to the first column */
}

/* Second column (usually for "Where") */
.work-timeline-table th:nth-child(2),
.work-timeline-table td:nth-child(2) {
    width: 30%; /* Allocate 35% of the table's width to the second column */
}

/* Third column (usually for "Period") */
.work-timeline-table th:nth-child(3),
.work-timeline-table td:nth-child(3) {
    width: 30%; /* Allocate 25% of the table's width to the third column */
}

/* Styling for the content inside both header and data cells */
/* This affects both regular content and the CustomTypeWriter component */
.work-timeline-table th > div,
.work-timeline-table td > div {
    white-space: nowrap; /* Prevent text from wrapping to the next line */
    overflow: hidden; /* Hide any content that exceeds the div's width */
    text-overflow: ellipsis; /* Show an ellipsis (...) for text that's cut off */
}

/* Education section title styling */
.education__title {
    padding: 8px 0; /* Add vertical padding around the title */
    border-bottom: solid 2px var(--secondary-color); /* Add a bottom border with the secondary color */
    margin-bottom: 10px; /* Space below the title */
}

/* Flexbox layout for education info */
.education__info {
    display: flex; /* Use flexbox for layout */
    justify-content: space-between; /* Distribute items evenly between start and end */
    align-items: center; /* Align items vertically in the center */
    margin-bottom: 15px; /* Add space below the info */
}

/* Styling for the institution name and period */
.education__where,
.education__period {
    font-style: italic; /* Italicize the text for a refined look */
}

/* Wrapper for the education description */
.education__description-wrapper {
    margin-top: 10px; /* Add space above the description */
}

/* Styling for the education description */
.education__description {
    margin-bottom: 10px; /* Add space below the description */
}

/* Styling for the education courses section */
.education__courses-title {
    margin-top: 10px; /* Space above the courses title */
    margin-bottom: 5px; /* Space below the courses title */
    font-weight: bold; /* Make the title bold */
}

/* List styling for courses */
.education__courses ul {
    list-style-type: disc; /* Use bullet points for the list */
    padding-left: 20px; /* Indent the list */
}

/* Wrapper for each education item */
.education_item {
    margin-bottom: 20px; /* Add space below each education item */
}

/* Education list styling */
.education_list {
    list-style: none; /* Remove default list styling */
    padding-left: 20px; /* Indent the list */
    margin: 0; /* Remove default margin */
}

/* Contact section styles */
.contact_info {
    font-size: 16px; /* Set a readable font size */
    line-height: 1.6; /* Improve readability with line spacing */
}

.contact_info p {
    margin-bottom: 10px; /* Space between paragraphs */
}

.contact_info a {
    color: var(--text-color); /* Use primary color for links */
    text-decoration: none; /* Remove underline from links */
}

.contact_info a:hover {
    text-decoration: underline; /* Underline on hover for clarity */
}

