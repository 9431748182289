/* Progress bar title styling */
.progress-title {
    font-size: 16px; /* Set font size for progress bar title */
    font-weight: 700; /* Bold font weight for emphasis */
    margin: 15px 0 20px; /* Space above and below the title */
    font-family: 'Raleway'; /* Use the Raleway font for a clean look */
}

/* General progress bar container styling */
.progress {
    height: 5px; /* Set height of the progress bar */
    background: var(--secondary); /* Use secondary color variable for background */
    border-radius: 0; /* Remove rounded corners */
    box-shadow: none; /* Remove any shadow for a flat design */
    margin-bottom: 30px; /* Adds space below the progress bar */
    overflow: visible; /* Allow overflow for displaying the value label */
}

/* Progress bar inner fill styling */
.progress .progress-bar {
    position: relative; /* Required for positioning the value label */
    background: var(--text-color); /* Use text color variable for progress fill */
    /* TODO: This should not be hard-coded, it should be possible to provide this value through code. */
    /* TODO: Use the duration parameter in the AnimatedProgressBarProps? */
    animation: animate-positive 2s; /* Animate progress bar filling over 2 seconds. */
    overflow: visible; /* Ensure the value label can overflow the bar */
    opacity: 0.9; /* Slight transparency for the bar */
}

/* Displaying the progress value above the progress bar */
.progress .progress-value {
    position: absolute; /* Absolutely position the value label */
    top: -30px; /* Position the label above the bar */
    right: 8px; /* Align to the right of the bar */
    font-size: 17px; /* Font size for the percentage value */
    font-weight: bold; /* Bold font weight for emphasis */
    font-style: italic; /* Italic style for a distinct look */
    color: var(--text-color); /* Use text color variable for the value */
}

/* Animation for progress bar filling */
@-webkit-keyframes animate-positive {
    0% {
        width: 0;
    }
    /* Start with 0 width for the animation */
}

@keyframes animate-positive {
    0% {
        width: 0;
    }
    /* Start with 0 width for the animation */
}

/* TODO: All of below can be removed? */

/* Animation for elements fading in and sliding up */
@-webkit-keyframes fadeInUp {
    0% {
        opacity: 0; /* Start fully transparent */
        -webkit-transform: translate3d(0, 100%, 0); /* Begin 100% down from the current position */
        transform: translate3d(0, 100%, 0);
    }
    to {
        opacity: 1; /* End fully opaque */
        -webkit-transform: translateZ(0); /* End at normal position */
        transform: translateZ(0);
    }
}

@keyframes fadeInUp {
    0% {
        opacity: 0; /* Start fully transparent */
        transform: translate3d(0, 100%, 0); /* Begin 100% down from the current position */
    }
    to {
        opacity: 1; /* End fully opaque */
        transform: translateZ(0); /* End at normal position */
    }
}

/* Class to apply the fadeInUp animation */
.fade-in-up {
    animation: fadeInUp 0.5s ease-in-out; /* Apply fade-in-up animation over 0.5 seconds */
}