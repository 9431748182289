/* custom_typewriter.css */

/* Container for the typewriter effect */
.typewriter-container {
    display: inline-block;
    position: relative; /* Establishes a positioning context for the cursor */
    min-height: 1.2em; /* Ensures consistent height */
}

/* Ensure the Typewriter component is displayed inline */
.typewriter-container .Typewriter {
    display: inline-block;
}

/* Position the cursor absolutely to prevent layout shifts */
.typewriter-container .Typewriter__cursor {
    position: relative;
    top: 0;
    animation: blink 0.7s infinite;
}

/* Cursor styling when typing is complete */
.typewriter-container.typing-complete .Typewriter__cursor {
    animation: none; /* Stop blinking animation */
    opacity: 0; /* Hide the cursor */
    transition: opacity; /* Smooth transition for cursor disappearance */
}

/* Placeholder Span Styles */
.typewriter-placeholder {
    display: inline-block;
    min-width: 1em;
}

/* Keyframes for cursor blinking animation */
@keyframes blink {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
