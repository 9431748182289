/* General section styling for all sections */
section {
    flex: 1 0 auto; /* Allows sections to grow and shrink */
    position: relative; /* Positioned relative for absolute positioning within */
    width: 100%; /* Full width */
    transition: all .5s ease-in; /* Smooth transition for any changes */
    overflow: hidden; /* Prevents any overflow */
    margin: 0; /* Ensures no margin */
    padding: 0; /* Ensures no padding */
}

/* Home section specific styles */
.home-section {
    height: 100vh; /* Full height of the viewport */
    display: flex; /* Use flexbox for centering */
    align-items: center; /* Center content vertically */
    justify-content: center; /* Center content horizontally */
}

/* Main container for intro content */
.intro-container {
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    display: flex; /* Use flexbox for layout */
    position: relative; /* For absolute positioning of children */
}

/* Background image styles */
.background-image {
    position: absolute; /* Position absolutely within container */
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover; /* Cover the entire container */
    background-position: center; /* Center the image */
    background-repeat: no-repeat; /* Do not repeat the image */
    z-index: -1; /* Place behind other content */
    filter: saturate(0.5); /* Apply filter for visual effect */
}

/* Content wrapper styles */
.content-wrapper {
    flex: 1; /* Take up all available space */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically */
    padding: 20px; /* Add some padding */
}

/* Center content styles */
.content-center {
    max-width: 700px; /* Maximum width for content */
    width: 100%; /* Full width up to max-width */
}

/* Intro content styles */
.intro-content {
    text-align: center; /* Center text */
    color: white; /* Set all text to white */
}

/* Main title styles */
.main-title {
    font-size: 30px; /* Font size */
    margin-bottom: 20px; /* Space below */
    font-weight: 700; /* Bold text */
}

/* Typewriter wrapper styles */
.typewriter-wrapper {
    margin-bottom: 20px; /* Space below */
    margin-top: 50px;
}

/* Typewriter text styles */
.typewriter-text {
    font-size: 24px; /* Font size */
}

/* Intro description styles */
.intro-description {
    font-size: 16px; /* Font size */
    margin-bottom: 30px; /* Space below */
}

/* Button group styles */
.button-group {
    display: flex; /* Use flexbox for layout */
    justify-content: center; /* Center buttons */
    gap: 20px; /* Space between buttons */
}

/* Button link styles */
.button-link {
    text-decoration: none; /* Remove underline */
    color: white; /* Ensure text is white */
}

/* Custom button styles */
.custom-button {
    padding: 19px 19px; /* Original padding */
    color: white; /* White text */
    position: relative; /* For positioning rings */
    border: 2px solid white; /* Explicitly define border width and color */
    overflow: hidden; /* Hide overflow for ring animation */
    transition: border-color 0.1s cubic-bezier(1, 0, 0.1, 1), box-shadow 0.1s cubic-bezier(1, 0, 0.1, 1); /* Transition only border-color and box-shadow */
    background: transparent; /* Transparent background */
    margin-top: 40px; /* Positioning */
}

/* Button hover effect */
.custom-button:hover {
    box-shadow: 6px 6px 0 white, -6px -6px 0 white; /* Original hover effect */
    border-color: transparent; /* Only change border color to transparent */
}


/* Responsive styles */
@media (max-width: 768px) {
    .intro-content {
        padding: 0 20px; /* Add horizontal padding on small screens */
    }

    .main-title {
        font-size: 24px; /* Smaller font size on small screens */
    }

    .typewriter-text {
        font-size: 20px; /* Smaller font size on small screens */
    }

    .intro-description {
        font-size: 14px; /* Smaller font size on small screens */
    }

    .button-group {
        flex-direction: column; /* Stack buttons vertically on small screens */
        align-items: center; /* Center buttons */
    }
}