/* Root CSS variables for theming */
:root {
    --bg-color: #0c0c0c; /* Background color for the app */
    --primary-color: #0d0d0d; /* Primary color for various elements */
    --secondary-color: #fff; /* Secondary color, often used for text */
    --text-color: #fff; /* Main text color */
    --text-color-2: #fff; /* Secondary text color */
    --text-color-3: rgb(204, 0, 0); /* Accent color for text or highlights */
    --overlay-color: rgb(12 12 12 / 63%); /* Overlay color with transparency */
}

/* Ensure full height for the html and body */
html,
body {
    height: 100%; /* Set full height to ensure proper layout */
}

/* Global body styles */
body {
    margin: 0; /* Remove default margin */
    height: 100%; /* Ensure full height of the body */
    overflow-x: hidden; /* Disable horizontal scrolling */
    overflow-y: visible; /* Allow vertical scrolling */
    background-color: var(--bg-color); /* Set background color from the variable */
    color: var(--text-color); /* Set text color from the variable */
    font-family: 'Raleway', sans-serif; /* Set global font to Raleway */
    -webkit-font-smoothing: antialiased; /* Smoother font rendering on WebKit browsers */
    -moz-osx-font-smoothing: grayscale; /* Smoother font rendering on macOS */
    padding-top: 0; /* Remove top padding */
    border-left: 0; /* Remove left border */
    border-right: 0; /* Remove right border */
}

/* Remove default list styles */
ul {
    list-style: none; /* Remove bullet points */
}

/* Headings (h1 to h6) styles */
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: Marcellus; /* Set font to Marcellus for headings */
}

/* Global link styles */
a,
a:hover {
    color: var(--text-color); /* Use main text color for links */
}

/* Paragraph styles */
p {
    word-break: break-word; /* Break long words if necessary */
    hyphens: auto; /* Enable automatic hyphenation */
}

/* Utility class to hide overflow */
.ovhidden {
    overflow: hidden; /* Hide overflow (both horizontal and vertical) */
}

/* Styling for secondary text color */
.text_2,
.text_2:hover {
    color: var(--text-color-2); /* Use secondary text color */
}

/* Code block styles */
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace; /* Set a monospaced font for code blocks */
}

/* Cursor dot container z-index to ensure it's on top */
.cursor__dot div {
    z-index: 999999 !important; /* Ensure the cursor dot is always on top */
}

/* Style for the last child of the cursor dot */
.cursor__dot div:last-child {
    background-color: white !important; /* Set background color to white */
}

/* Style for the first child of the cursor dot */
.cursor__dot div:first-child {
    filter: invert(1); /* Invert colors for contrast */
    background-color: var(--overlay-color) !important; /* Use overlay color for the background */
}

/* Primary color styling */
.color_pr {
    color: var(--primary-color) !important; /* Use primary color for text or elements */
}

/* Secondary color styling */
.color_sec {
    color: var(--secondary-color); /* Use secondary color for text or elements */
}
